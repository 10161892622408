import React, {Component} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TableViewIcon from '@mui/icons-material/TableView';
import ListSubheader from '@mui/material/ListSubheader';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BusinessIcon from '@mui/icons-material/Business';
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {ACCOUNT, ADMIN, BILL, CLAIM, RATER, SETTINGS, SHIPMENT, USER} from "../redux/type";
import {connect} from "react-redux";
import {changePage} from "../redux/actions/indexAction";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import '../css/component.css'
import {Collapse, Popover} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

import LogisticSupplier from '../pages/logisticSupplierManagement/logisticSupplierPage'
import ClaimManagement from "../pages/claimManagement/ClaimManagementPage";
import FBAAddress from "../pages/FBAAddress/FBAAddress"
import FreightShipment from "../pages/FreightShipment/FreightShipment"
import ZonePage from "../pages/logisticSupplierManagement/zone/zone";
import FuelSurcharge from "../pages/FuelSurcharge/FuelSurchargePage"
import FuseManagementPage from "../pages/FuseManagement/FuseManagementPage"

import Pickup from "../pages/pickup/Pickup"
import AccountSignIn from "../pages/LogisticAccountManagement/AccountSignInPage"
import FireTruckIcon from '@mui/icons-material/FireTruck';
import PublicIcon from '@mui/icons-material/Public';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const MENU_ITEMS_MAPPING = {
    logisticSupplier: [
        { path: "LogisticSupplier", label: "渠道商管理" },
        { path: "Warehouse", label: "仓库管理" },
        { path: "LogisticService", label: "渠道服务管理" },
        { path: "ServiceAndUser", label: "服务与用户管理" },

        { path: "ServiceAndSpecial", label: "服务与特殊服务设置" },
        { path: "UserPackageSpecialServices", label: "用户与特殊服务设置" },
        { path: "ServiceAndWarehouse", label: "服务与仓库设置" },
    ],
    claimManagement: [
        { path: "InternalClaims", label: "内部索赔", auth: true},
        { path: "CustomerClaims", label: "客户索赔" },
        // 其他子目录项...
    ],
    freight: [
        {path: "FreightShipment", label: "卡派管理"},
        {path: "Pickup", label: "预约管理"}
    ],
    rater_management: [
        {path: "RATER", label: "报价表"},
        {path: "FuelSurcharge", label: "燃油配置"},
        {path: "Zone", label: "分区管理"}
    ],

    logistic_account_management: [
        {path: "AccountSignIn", label: "新建账号登记"},
        { path: "WarehouseAndAccount", label: "渠道账号管理" },
    ],
    // 其他主菜单项ID及其子目录项...
};

class MainListItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedMenus: {},
            popoverOpen: false,
            drawerOpen: props.drawerOpen,
            anchorEl: null,
            activeMenuId: null, // 新增状态，用于追踪当前活跃的菜单项ID
        };

    }

    handleClickMenuItem = (menuId) => (event) => {
        console.log(event)
        this.setState(prevState => ({
            expandedMenus: {
                ...prevState.expandedMenus,
                [menuId]: !prevState.expandedMenus[menuId],
            },
            popoverOpen: !this.props.drawerOpen,
            anchorEl: !this.props.drawerOpen ? event.currentTarget : null,
            activeMenuId: !this.props.drawerOpen ? menuId : null,
        }));
    };


    componentDidUpdate(prevProps) {
        if (prevProps.drawerOpen && !this.props.drawerOpen) {
            // 关闭所有已展开的子菜单
            this.setState({ expandedMenus: {}, popoverOpen: false });
        }
    }

    handlePopoverClose = () => {
        this.setState({ popoverOpen: false, anchorEl: null });
    };

    changePage = (toPage) => {
        this.props.changePage(toPage, this.props.history)
    }

    renderListItemButton = (pageType, IconComponent, text, onClick) => (
        <ListItemButton
            divider={true}
            onClick={onClick || (() => this.changePage(pageType))}
            style={{ backgroundColor: this.props.current === pageType ? '#c5ddf6' : '#ffffff' }}
        >
            <ListItemIcon><IconComponent /></ListItemIcon>
            <ListItemText primary={text} />
            {onClick && (this.state.openLogisticSupplierSubMenu ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
    );

    renderSubListItemButton = (pageType, text) => (
        <ListItemButton
            key={pageType}
            sx={{
                pl: this.props.drawerOpen && !this.state.popoverOpen ? 10 : 3 // 当侧边栏展开且Popover未打开时，设置 paddingLeft 为 11，否则为 0
            }}
            onClick={() => this.changePage(pageType)}
        >
            <ListItemText primary={text} />
        </ListItemButton>
    );

    renderSubListItems = (menuId, items) => (
        <Collapse in={this.state.expandedMenus[menuId] || false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {items.map(item => {
                    if (!item.auth) {
                        return this.renderSubListItemButton(item.path, item.label);
                    }else if (item.auth && this.props.user.is_superuser){
                        return this.renderSubListItemButton(item.path, item.label);
                    }
                })}
            </List>
        </Collapse>
    );

    getMenuItemsForMenuId = (menuId) => {
        // 返回与menuId对应的子目录项列表，如果没有找到，返回空数组
        return MENU_ITEMS_MAPPING[menuId] || [];
    };

    render() {
        if (!this.props.isAuthenticated) {
            return null;
        }
        return (
            <Box>
                <List component="nav" sx={{
                    width : 231,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxHeight: 830,
                }}>
                    {this.renderListItemButton(USER, HomeIcon, "主页")}
                    {this.renderListItemButton(SHIPMENT, TableViewIcon, "运单")}
                    {this.renderListItemButton(BILL, ShoppingCartIcon, "对账")}
                    {this.renderListItemButton(SETTINGS, SettingsIcon, "设置")}
                    {this.props.user.is_superuser && (
                        <>
                            {this.renderListItemButton(ACCOUNT, AccountBoxIcon, "账户")}
                            {this.renderListItemButton(ADMIN, ManageAccountsIcon, "管理员")}
                            {/*{this.renderListItemButton(RATER, PriceChangeIcon, "报价表")}*/}
                        </>
                    )}
                            {/* 直接在这里调用renderSubListItems来渲染“渠道管理”和“索赔管理”的子菜单项 */}
                            {this.renderListItemButton(ClaimManagement, TableViewIcon, "索赔管理", this.handleClickMenuItem("claimManagement"))}
                            {this.renderSubListItems("claimManagement", this.getMenuItemsForMenuId("claimManagement"))}
                    {this.props.user.is_superuser && (
                        <>
                            {this.renderListItemButton(LogisticSupplier, PrecisionManufacturingIcon, "渠道管理", this.handleClickMenuItem("logisticSupplier"))}
                            {this.renderSubListItems("logisticSupplier", this.getMenuItemsForMenuId("logisticSupplier"))}
                            {this.renderListItemButton(FBAAddress, BusinessIcon, "FBA地址管理")}
                            {/*{this.renderListItemButton(ZonePage, PublicIcon, "分区管理")}*/}
                            {/*{this.renderListItemButton(Pickup, EventNoteIcon, "预约管理")}*/}
                        </>
                    )}
                    {this.renderListItemButton(FreightShipment, FireTruckIcon , "卡派管理", this.handleClickMenuItem("freight"))}
                    {this.renderSubListItems("freight", this.getMenuItemsForMenuId("freight"))}
                    {this.props.user.is_superuser && (<>
                        {this.renderListItemButton(RATER, PriceChangeIcon , "报价管理", this.handleClickMenuItem("rater_management"))}
                        {this.renderSubListItems("rater_management", this.getMenuItemsForMenuId("rater_management"))}
                    </>)}
                    {this.renderListItemButton(FuseManagementPage, HomeIcon, "Fuse管理")}
                    {this.props.user.is_superuser && (<>
                        {this.renderListItemButton(AccountSignIn, AssignmentIndIcon , "渠道账号管理", this.handleClickMenuItem("logistic_account_management"))}
                        {this.renderSubListItems("logistic_account_management", this.getMenuItemsForMenuId("logistic_account_management"))}
                    </>)}
                </List>
                <Popover
                    open={this.state.popoverOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    // anchorOrigin={{
                        // vertical: 'bottom',
                        // horizontal: 'center',
                    // }}
                    // transformOrigin={{
                        // vertical: 'top',
                        // horizontal: 'center',
                    // }}
                >
                    {this.state.popoverOpen && this.renderSubListItems(this.state.activeMenuId, this.getMenuItemsForMenuId(this.state.activeMenuId))}
                </Popover>
            </Box>
        );
    }

}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    current: state.indexReducer.current
});

export default connect(mapStateToProps, { changePage })(MainListItems);
