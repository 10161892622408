import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {Autocomplete, Popper} from '@mui/material';
import {useDispatch} from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import {state}  from "./module";
import {RenderSelectField, RenderTextField} from "../../../../components/renderField";

const CreateNewAccount = ({Refresh}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [return_type_options, setReturnTypeOptions] = useState([])
    const [warehouse_info, setWarehouseInfo] = useState(null);
    const [formData, setFormData] = useState({
        accountNumber: null,
        accountName: null,
        returnType: null,
        accountType: null,
        address1: null,
        address2: null,
        postal_code: null,
        country: 'US',
        state: '',
        hubId: null,
        suffix: null,
        city: null,
        remark: null,

    });
    const [statusFormData, setStatusFormData] = useState({
        accountNumber: {required: true, message: '', status: false},
        accountName: {required: true, message: '', status: false},
        returnType: {required: true, message: '', status: false},
        address1: {required: true, message: '', status: false},
        address2: {required: false, message: '', status: false},
        city: {required: true, message: '', status: false},
        state: {required: true, message: '', status: false},
        postal_code: {required: true, message: '', status: false},
        accountType: {required: true, message: '', status: false},
        hubId: {required: false, message: '', status: false},
        suffix: {required: false, message: '', status: false},
        country: {required: true, message: '', status: false},
        remark: {required: false, message: '', status: false},
    })
        function validateZipCode(zipCode) {
            // 正则表达式匹配五位数字或五位数字-四位数字格式
            const zipCodeRegex = /^\d{5}(-\d{4})?$/;
            return zipCodeRegex.test(zipCode);
        }


    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const [account_type_options, setAccountTypeOptions] = useState([])
    const fetchAccountTypeData = async () => {
            try {
                setLoading(true)
                let maxNumber = 0
                await AxiosSyncDefaultRequest({
                    url: server.account_type,
                    method: 'GET',

                    success: (res) => {
                        setAccountTypeOptions(res.data.results)
                        setLoading(false)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                setLoading(false)
                console.error('请求异常', error);
            }
        };
    const fetchReturnTypeData = async () => {
            try {
                setLoading2(true)
                let maxNumber = 0
                await AxiosSyncDefaultRequest({
                    url: server.return_type,
                    method: 'GET',

                    success: (res) => {
                        setReturnTypeOptions(res.data.results)
                        setLoading2(false)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                setLoading2(false)
                console.error('请求异常', error);
            }
        };
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true)
        setLoading2(true)
        // fetchWareData();
        fetchReturnTypeData()
        fetchAccountTypeData()

    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        // 验证输入值是否超过最大值
        if (name === "accountNumber" && value > 999999999) {
            // 如果超过最大值，可以阻止更新状态或显示错误提示
            // setStatusFormData((prev) => ({
            //     ...prev,
            //     accountNumber: {
            //         ...prev.accountNumber,
            //         status: true,
            //         message: "最大值不能超过 999,999,999",
            //     },
            // }));
            return; // 阻止更新状态
        }
        if(name === "hubId" && value > 9999){
            return;
        }
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));


    };

    function validateZipCode(zipCode) {
        // 正则表达式匹配五位数字或五位数字-四位数字格式
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        return zipCodeRegex.test(zipCode);
    }
    function validateAccountName(accountName) {
        // 正则表达式匹配3个字母开头加1-2位数字
        const accountNameRegex = /^[a-zA-Z]{3}\d{1,2}$/;
        return accountNameRegex.test(accountName);
    }
    function validateSuffix(suffix) {
        // 正则表达式匹配3个字母开头加1-2位数字
        const suffixRegex = /^[a-zA-Z]{1,3}$/;
        return suffixRegex.test(suffix);
    }

    const handleSave = async () => {


        // 创建一个新的对象来存储错误
        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {

                if (statusFormData[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                } else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }
            // }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }
        let save = true
        if (!validateZipCode(formData.postal_code)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                postal_code: {required: true, message: "请输入正确的邮编！", status: true}
            }));
        }
        if (!validateAccountName(formData.accountName)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                accountName: {required: true, message: "请输入正确的Account Name！", status: true}
            }));
        }
        if(formData.suffix){
            if(!validateSuffix(formData.suffix)){
                save = false
                setStatusFormData(prevPerson => ({
                    ...prevPerson,
                    suffix: {required: true, message: "后缀必须是3位以内的字母！", status: true}
                }));
            }
        }
        if(formData.accountNumber.length !== 9  || String(formData.accountNumber).includes('.')){
            save=false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                accountNumber: {required: true, message: "必须是9位整数数字！", status: true}
            }));
        }
        if(formData.hubId){
            if(formData.hubId.length !== 4  || String(formData.hubId).includes('.')){
                save=false
                setStatusFormData(prevPerson => ({
                    ...prevPerson,
                    hubId: {required: true, message: "必须是4位整数数字！", status: true}
                }));
            }
        }

        if(!save){
            return
        }

        try {

            setLoading(true);
            // let new_data = JSON.parse(JSON.stringify(formData));
            return await AxiosSyncDefaultRequest({
                url: server.new_account,
                method: "POST",
                data: formData,
                success:(res) => {
                    fetchAccountTypeData()
                    fetchReturnTypeData()
                    setFormData({
                        accountNumber: null,
                        accountName: null,
                        returnType: null,
                        accountType: null,
                        address1: null,
                        address2: null,
                        postal_code: null,
                        country: 'US',
                        state: '',
                        hubId: null,
                        suffix: null,
                        city: null,
                        remark: null
                    })
                    console.log(res)
                    setSnackbar({open: true, severity: "success", message: "创建成功"})
                    Refresh()

            },
                fail: (error) => {
                    console.error('请求失败', error);
                    if(error.response && error.response.data && error.response.data.error && error.response.data.error.non_field_errors){
                        setSnackbar({open: true, severity: "error", message: error.response.data.error.non_field_errors[0]})
                    }
                    // setData([]);
                    setLoading(false);
                    // const errorMessage = JSON.stringify(error.response.data);
                    // setSnackbar({ open: true, message: error, severity: 'error' });
                    // throw error
                }
            })
            // const result = await onSave(formData, 'POST');
            // if (result.status === true) {
            //     setFormData({
            //         accountNumber: null,
            //         accountName: null,
            //         returnType: null,
            //         accountType: null,
            //         address1: null,
            //         hubId: null,
            //         suffix: null
            //     })
            //     fetchAccountTypeData()
            //     fetchReturnTypeData()
                // 操作成功，显示成功消息
                // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
            }
         catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }


    };
    const CustomPopper = styled(Popper)(({ theme }) => ({
        '& .MuiAutocomplete-listbox': {
            maxHeight: 200, // 限制下拉框高度
            overflow: 'auto', // 添加滚动条
        },
    }));
    const handleFieldChange = (e, value, key, value_name) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value?value[value_name]:null,
        }));
    }
    // const defaultState = state.find(state => state.abbreviation === formData.state) || null;
    // const defaultUser = user_options.find(user_options => user_options.id == formData.user) || null
        return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<AccountCircleIcon/>} onClick={() => {
                onOpen()
            }}>
                新增账号
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>新增账号记录</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="accountNumber"
                                label="Account Number"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.accountNumber}
                                onChange={handleChange}
                                required={statusFormData.accountNumber.required}
                                helperText={statusFormData.accountNumber.message}
                                error={statusFormData.accountNumber.status}
                                inputProps={{
                                    minLength: 1,
                                    maxLength: 9
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="accountName"
                                label="Account Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.accountName}
                                onChange={handleChange}
                                required={statusFormData.accountName.required}
                                error={statusFormData.accountName.status}
                                helperText={statusFormData.accountName.message}
                                inputProps={{
                                    minLength: 1,
                                    maxLength: 5
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="suffix"
                                label="后缀"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.suffix}
                                onChange={handleChange}
                                inputProps={{
                                    minLength: 1,
                                    maxLength: 3
                                }}
                                required={statusFormData.suffix.required}
                                helperText={statusFormData.suffix.message}
                                error={statusFormData.suffix.status}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address1"
                                label="地址1"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address1}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 35
                                }}
                                required={statusFormData.address1.required}
                                error={statusFormData.address1.status}
                                helperText={statusFormData.address1.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address2"
                                label="地址2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address2}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 35
                                }}
                                required={statusFormData.address2.required}
                                error={statusFormData.address2.status}
                                helperText={statusFormData.address2.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="city"
                                label="城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.city}
                                onChange={handleChange}
                                // inputProps={{
                                //     maxLength: 35
                                // }}
                                required={statusFormData.city.required}
                                error={statusFormData.city.status}
                                helperText={statusFormData.city.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="state"
                                label="州/省简称"
                                value={formData.state}
                                select_options={state}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "state", "abbreviation")
                                }}
                                v={"abbreviation"}
                                value_name={"abbreviation"}
                                sx={{width: 350}}
                                required={statusFormData.returnType.required}
                                helperText={statusFormData.returnType.message}
                                error={statusFormData.returnType.status}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="邮编"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.postal_code}
                                onChange={handleChange}
                                // inputProps={{
                                //     maxLength: 35
                                // }}
                                required={statusFormData.postal_code.required}
                                error={statusFormData.postal_code.status}
                                helperText={statusFormData.postal_code.message}
                            />
                        </Grid>
                        {/*TODO 目前只支持美国，那就先不要选了 */}
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="国家"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.country}
                                onChange={handleChange}
                                select
                                // inputProps={{
                                //     maxLength: 35
                                // }}
                                required={statusFormData.country.required}
                                error={statusFormData.country.status}
                                helperText={statusFormData.country.message}
                            >
                                <MenuItem key="country_US" value={"US"}>
                                    US
                                </MenuItem>
                            </TextField>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="hubId"
                                label="HUBID"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.hubId}
                                onChange={handleChange}
                                inputProps={{
                                    // minLength: 1,
                                    maxLength: 4
                                }}
                                required={statusFormData.hubId.required}
                                error={statusFormData.hubId.status}
                                helperText={statusFormData.hubId.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="remark"
                                label="备注"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.remark}
                                onChange={handleChange}
                                inputProps={{
                                    // minLength: 1,
                                    maxLength: 200
                                }}
                                required={statusFormData.remark.required}
                                error={statusFormData.remark.status}
                                helperText={statusFormData.remark.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="accountType"
                                label="Account Type"
                                value={formData.accountType}
                                select_options={account_type_options}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "accountType", "id")
                                }}
                                v={"id"}
                                value_name={"accountTypeName"}
                                sx={{width: 350}}
                                required={statusFormData.accountType.required}
                                helperText={statusFormData.accountType.message}
                                error={statusFormData.accountType.status}
                            />
                        </Grid>
                                    <Grid item xs={12} md={8}>
                                        <RenderSelectField
                                            idAname="returnType"
                                            label="Return Type"
                                            value={formData.returnType}
                                            select_options={return_type_options}
                                            onChangeHandler={(e, value) => {
                                                handleFieldChange(e, value, "returnType", "id")
                                            }}
                                            v={"id"}
                                            value_name={"returnTypeName"}
                                            sx={{width: 350}}
                                            required={statusFormData.returnType.required}
                                            helperText={statusFormData.returnType.message}
                                            error={statusFormData.returnType.status}
                                        />
                                </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <LoadingButton loading={loading || loading2} onClick={handleSave} variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
}
    ;

    export default CreateNewAccount;
