import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {Autocomplete, Popper} from '@mui/material';
import {useDispatch} from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import {state}  from "./module";

const CreateWarehouse = ({onSave}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user_options, setUserOptions] = useState([])
    const [warehouse_info, setWarehouseInfo] = useState(null)
    const [formData, setFormData] = useState({
        id: '',
        warehouse_number: warehouse_info?warehouse_info.maxNumber + 1:'',
        name: '',
        IATA: '',
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        state: '',
        phone: '0000000000',
        company_name: 'Shipping dept',
        country: 'US',
        nameToChinese: "美东",
        is_specific: false,
        address_type: 1,
        online_city: '',


    });
    const [statusFormData, setStatusFormData] = useState({
        warehouse_number: {required: true, message: '', status: false},
        IATA: {required: true, message: '', status: false},
        name: {required: true, message: '', status: false},
        address1: {required: true, message: '', status: false},
        city: {required: true, message: '', status: false},
        postal_code: {required: true, message: '', status: false},
        state: {required: true, message: '', status: false},
        phone: {required: true, message: '', status: false},
        company_name: {required: true, message: '', status: false},
        country: {required: true, message: '', status: false},
        nameToChinese: {required: true, message: '', status: false},
        is_specific: {required: true, message: '', status: false},
        address_type: {required: true, message: '', status: false},
        online_city: {required: false, message: '', status: false},
    })


    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const [supplier_options, setSupplierOptions] = useState([])
    const fetchWareData = async () => {
            try {
                setLoading(true)
                let maxNumber = 0
                await AxiosSyncDefaultRequest({
                    url: `${server.warehouse_list_url}?offset=0&page_size=1000`,
                    method: 'GET',

                    success: (res) => {
                        let options = {warehouse_number: [], name: []}
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.warehouse_number.push(rows[i].warehouse_number)
                            options.name.push(rows[i].name)
                            if(rows[i].warehouse_number > maxNumber) {
                                maxNumber = rows[i].warehouse_number
                            }
                        }
                        options["maxNumber"] = maxNumber + 1
                        setFormData((prev) => ({
                            ...prev,
                            warehouse_number: maxNumber + 1,
                        }));
                        setWarehouseInfo(options)
                        setLoading(false)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                setLoading(false)
                console.error('请求异常', error);
            }
        };
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true)
        fetchWareData();
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleSelect = (event, newValue, key) => {
        // console.log("event",event)
        if(key === "state"){
            setFormData((prevData) => ({
                ...prevData,
                state: newValue ? newValue.abbreviation : '' // 更新为选中的值或空字符串
            }));
        }
        if(key === "user"){
            setFormData((prevData) => ({
                ...prevData,
                user: newValue ? newValue.id : '' // 更新为选中的值或空字符串
            }));
        }

    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));


    };

    function validateZipCode(zipCode) {
        // 正则表达式匹配五位数字或五位数字-四位数字格式
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        return zipCodeRegex.test(zipCode);
    }

    function isPositiveIntegerWithLimit(str) {
        const regex = /^[1-9]\d{0,9}$/;
        return regex.test(str);
    }

    const handleSave = async () => {
        // let data = formData
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (value === '') {
        //         delete data[key]
        //     }
        // });

        // 创建一个新的对象来存储错误
        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {
            if (field === "is_specific"){
                if(typeof formData[field] !== 'boolean'){
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                }
                else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }

            }
            else {
                if (statusFormData[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                } else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }
            }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }


        let save = true
        if (!validateZipCode(formData.postal_code)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                postal_code: {required: true, message: "请输入正确的邮编！", status: true}
            }));
        }
        if (!isPositiveIntegerWithLimit(formData.warehouse_number)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                warehouse_number: {required: true, message: "仓库编号只支持正整数！", status: true}
            }));
        }
        if (formData.IATA.length < 3) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                IATA: {required: true, message: "IATA长度为3-4位！", status: true}
            }));
        }
        if (warehouse_info.warehouse_number.includes(parseInt(formData.warehouse_number))) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                warehouse_number: {required: true, message: "仓库编号已经存在，请更改！", status: true}
            }));
        }
        if( warehouse_info.name.includes(formData.name)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                name: {required: true, message: "仓库名已经存在，请更改！", status: true}
            }));
        }
        if (formData.country.length < 2 || formData.country.length > 3) {
            save=false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                country: {required: true, message: "国家/地区长度为2-3位！", status: true}
            }));

        }
        // 以上如果不满足规则，则不请求
        if (!save) {
            return
        }
        try {

            setLoading(true);
            // let new_data = JSON.parse(JSON.stringify(formData));

            const result = await onSave(formData, formData.id, 'POST');
            if (result.status === true) {
                setFormData({
                    id: '',
                    warehouse_number: warehouse_info?warehouse_info.maxNumber + 1:'',
                    name: '',
                    IATA: '',
                    address1: '',
                    address2: '',
                    city: '',
                    postal_code: '',
                    state: '',
                    online_city: '',
                    phone: '0000000000',
                    company_name: 'Shipping dept',
                    country: 'US',
                    nameToChinese: "美东",
                    is_specific: false,
                    address_type: 1
                })
                fetchWareData()
                // 操作成功，显示成功消息
                // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }


    };
    const CustomPopper = styled(Popper)(({ theme }) => ({
        '& .MuiAutocomplete-listbox': {
            maxHeight: 200, // 限制下拉框高度
            overflow: 'auto', // 添加滚动条
        },
    }));
    const defaultState = state.find(state => state.abbreviation === formData.state) || null;
    // const defaultUser = user_options.find(user_options => user_options.id == formData.user) || null
        return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<WarehouseIcon/>} onClick={() => {
                onOpen()
            }}>
                新增仓库
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>新增仓库管理记录</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="warehouse_number"
                                label="仓库编号"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.warehouse_number}
                                onChange={handleChange}
                                required={statusFormData.warehouse_number.required}
                                helperText={statusFormData.warehouse_number.message}
                                error={statusFormData.warehouse_number.status}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="仓库名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required={statusFormData.name.required}
                                error={statusFormData.name.status}
                                helperText={statusFormData.name.message}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="IATA"
                                label="IATA"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.IATA}
                                onChange={handleChange}
                                inputProps={{
                                    minLength: 3,
                                    maxLength: 4
                                }}
                                required={statusFormData.IATA.required}
                                helperText={statusFormData.IATA.message}
                                error={statusFormData.IATA.status}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address1"
                                label="地址一"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address1}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 35
                                }}
                                required={statusFormData.address1.required}
                                error={statusFormData.address1.status}
                                helperText={statusFormData.address1.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address2"
                                label="地址二"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="city"
                                label="城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.city}
                                onChange={handleChange}
                                required={statusFormData.city.required}
                                error={statusFormData.city.status}
                                helperText={statusFormData.city.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="邮编"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.postal_code}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 10
                                }}
                                required={statusFormData.postal_code.required}
                                error={statusFormData.postal_code.status}
                                helperText={statusFormData.postal_code.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="online_city"
                                label="上网城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.online_city}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 50
                                }}
                                required={statusFormData.online_city.required}
                                error={statusFormData.online_city.status}
                                helperText={statusFormData.online_city.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Autocomplete
                                value={defaultState}
                                onChange={(event, newValue) => handleSelect( event, newValue, "state")}
                                options={state}
                                getOptionLabel={(option) => option.abbreviation}
                                renderInput={(params) => <TextField {...params} label="州"
                                        margin="dense"
                                        fullWidth
                                        type="text"
                                        variant="standard"

                                        required={statusFormData.state.required}
                                            error={statusFormData.state.status}
                                            helperText={statusFormData.state.message}
                                            />}
                                            filterOptions={(options, states) =>
                                            options.filter(option =>
                                            option.abbreviation.toLowerCase().includes(states.inputValue.toLowerCase())
                                            )

                                        }
                                    PopperComponent={(props) => <CustomPopper {...props} />}
                                            />
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                    <TextField
                                        margin="dense"
                                        name="phone"
                                        label="电话"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        inputProps={{
                                            maxLength: 14  // 最大长度，包括格式字符
                                        }}
                                        required={statusFormData.phone.required}
                                        error={statusFormData.phone.status}
                                        helperText={statusFormData.phone.message}
                                    />
                                </Grid>
                                    <Grid item xs={12} md={8}>
                                <TextField
                                    margin="dense"
                                    name="company_name"
                                    label="公司名"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    required={statusFormData.company_name.required}
                                    error={statusFormData.company_name.status}
                                    helperText={statusFormData.company_name.message}
                                />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="country"
                                label="国家/地区"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.country}
                                onChange={handleChange}
                                required={statusFormData.country.required}
                                error={statusFormData.country.status}
                                helperText={statusFormData.country.message}
                                inputProps={{
                                    maxLength: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="nameToChinese"
                                label="中文名"
                                type="nameToChinese"
                                fullWidth
                                variant="standard"
                                value={formData.nameToChinese}
                                onChange={handleChange}
                                required={statusFormData.nameToChinese.required}
                                error={statusFormData.nameToChinese.status}
                                helperText={statusFormData.nameToChinese.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_specific"
                                label="是否退回美西"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_specific}
                                onChange={handleChange}
                                required={statusFormData.is_specific.required}
                                error={statusFormData.is_specific.status}
                                helperText={statusFormData.is_specific.message}
                            >
                                <MenuItem key="is_specific_false" value={false}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_specific_true" value={true}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address_type"
                                label="address_type"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.address_type}
                                onChange={handleChange}
                                required={statusFormData.address_type.required}
                                error={statusFormData.address_type.status}
                                helperText={statusFormData.address_type.message}
                            >
                                <MenuItem key="address_type_1" value={1}>
                                    只允许正向物流
                                </MenuItem>
                                <MenuItem key="address_type_2" value={2}>
                                    支持正逆向物流
                                </MenuItem>
                                <MenuItem key="address_type_3" value={3}>
                                    只允许逆向物流
                                </MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
}
    ;

    export default CreateWarehouse;
