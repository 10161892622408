import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    MultiSelectField,
    RenderButtonField, RenderCloseField, renderCloseField, RenderDateRangePickerField,
    RenderDesktopDatePickerField,
    RenderSelectField, RenderSelectOrInputField,
    RenderTextField
} from "../../../components/renderField";
import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExportPage from "../../modal/ExportPageModal";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import server from "../../../server"
import {AxiosSyncDefaultRequest} from "../../../components/default_request";
import ReceiptPage from "../../modal/ReceiptPageModal";
import Tooltip from "@mui/material/Tooltip";
const options = [
    { label: "Submitted", value: "Submitted"},
    { label: "Unqualified", value: "Unqualified"},
    { label: "Denied", value: "Denied"},
    { label: "Open", value: "Open"},
    { label: "Paid", value: "Paid"},
    { label: "Closed Without Payment", value: "Closed Without Payment"},
    { label: "Resubmitted", value: "Resubmitted"},
    { label: "出账中", value: "出账中"}
];
const claim_options = [
    { label: "Misdelivery", value: "Misdelivery"},
    { label: "Shipment Not Received", value: "Shipment Not Received"},
    { label: "Shipment Missing Contents", value: "Shipment Missing Contents"},
    { label: "Shipment Damaged", value: "Shipment Damaged"},
    { label: "COD Payment Not Collected", value: "COD Payment Not Collected"},
];
const returned = [
    { label: "处理中", value: "处理中"},
    { label: "未退款", value: "未退款"},
    { label: "已退款", value: "已退款"}
]
// const supplier_options = [
//     <MenuItem aria-label="None" key={'supplier_none'} value={""}>不选择</MenuItem>,
//     <MenuItem aria-label="None" key={'Fedex'} value={"Fedex"}>Fedex</MenuItem>,
//     <MenuItem aria-label="None" key={'SMP'} value={"SMP"}>SMP</MenuItem>,
//     <MenuItem aria-label="None" key={'UPS'} value={"UPS"}>UPS</MenuItem>,
//     <MenuItem aria-label="None" key={'USPS'} value={"USPS"}>USPS</MenuItem>,
//     <MenuItem aria-label="None" key={'Other'} value={"Other"}>Other</MenuItem>,
// ]
const supplier_options = [
    {supplier_name: "Fedex"},
    {supplier_name: "SMP"},
    {supplier_name: "UPS"},
    {supplier_name: "USPS"},
    {supplier_name: "Other"},
]
const payment_type_options = [
    {label: "NONE", value: 0},
    {label: "Check", value: 1},
    {label: "EFT", value: 2}
]

const searchFormControl = {width: '80%', marginBottom: '5%'};
export function CustomerClaimBar({searchData, setSearchData, search, setSearch, setCurrentPage}) {
    const [searchOpen, setSearchOpen] = useState(true);
    const [userData, setUserData] = useState([]);
    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
        setCurrentPage(0);
        setSearch()
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        AxiosSyncDefaultRequest({
            url: server.claims_user_uid_list,
            method: "GET",
            success: (res) => {
                const rows = res.data || [];
                setUserData(rows);
            },
            fail: (error) => {
                console.error('请求失败', error);
                setUserData([]);
            }
        });
    };

    const handleUIDFieldChange = (fieldName) => (eventOrValue) => {
        if (typeof eventOrValue === "string"){
            setSearchData({ ...searchData, [fieldName]: eventOrValue });
        }else{
            // 直接使用newValue，不需要通过event.target.value获取
            const newValue = eventOrValue.target ? eventOrValue.target.value : eventOrValue.value;
            setSearchData({ ...searchData, [fieldName]: newValue });
        }
    };
    const handleFieldChanges = (e, value, key, value_name) => {
        setSearchData({...searchData, [key]: value?value[value_name]:''})
    }

    const handleFieldChange = (fieldName) => (event) => {
        const newValue = event.target.value;
        const updateValue = Array.isArray(newValue) ? newValue : [newValue];
        setSearchData({ ...searchData, [fieldName]: updateValue });
    };

    const handleButtonFieldChange = (fieldName, value) => () => {
        setSearchData({ ...searchData, [fieldName]: value });
    };

    const handleDateFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]: new Date(event).toLocaleDateString("en") });
    };

    const handleDateRangeFieldChange = (startFieldName, endFieldName) => (newValue) => {
        // newValue 是一个数组，包含两个元素：[开始日期, 结束日期]
        const [startValue, endValue] = newValue;

        // 使用 toLocaleDateString 将日期转换为字符串
        const startDateString = startValue ? new Date(startValue).toLocaleDateString("en") : null;
        const endDateString = endValue ? new Date(endValue).toLocaleDateString("en") : null;

        // 同时更新开始和结束日期
        setSearchData({
            ...searchData,
            [startFieldName]: startDateString,
            [endFieldName]: endDateString
        });
    };

    const handleClearData = () => {
        setSearchData(prevSearchData => {
            const resetData = Object.keys(prevSearchData).reduce((acc, key) => {
                // 对于四个特定的时间字段，将它们的值设置为null
                if (["start_check_time", "start_upload_time", "end_check_time", "end_upload_time"].includes(key)) {
                    acc[key] = null;
                } else if (["status", "returned", "claim_type"].includes(key)) {
                    acc[key] = [];
                }else {
                    // 对于其他字段，将它们的值设置为空字符串""
                    acc[key] = "";
                }
                return acc;
            }, {});

            return { ...resetData };
        });

    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="Case_number"
                            label="Case_number"
                            value={searchData.Case_number}
                            onChangeHandler={handleFieldChange('Case_number')}
                        />
                        <RenderTextField
                            idAname="Tracking_number"
                            label="Tracking Number"
                            value={searchData.Tracking_number}
                            onChangeHandler={handleFieldChange('Tracking_number')}
                        />
                        <RenderTextField
                            idAname="EFT"
                            label="EFT"
                            value={searchData.EFT}
                            onChangeHandler={handleFieldChange('EFT')}
                        />
                        <RenderSelectOrInputField
                            idAname="UID"
                            label="用户UID"
                            value={searchData.UID}
                            onChangeHandler={(event) => handleUIDFieldChange('UID')(event.target.value)} // 假设handleFieldChange是一个返回函数的高阶函数
                            options={userData} // 直接传递userData数组
                        />
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderSelectField
                            idAname="supplier"
                            label="渠道"
                            value={searchData.supplier}
                            onChangeHandler={(e, newValue) => handleFieldChanges(e, newValue, "supplier", "supplier_name")}
                            select_options={supplier_options}
                            v="supplier_name"
                            value_name="supplier_name"
                        ></RenderSelectField>
                        {/*<Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>*/}
                        {/*    <Grid container spacing={1}>*/}
                        {/*        <RenderDateRangePickerField*/}
                        {/*            idAname={['start_upload_time', 'end_upload_time']}*/}
                        {/*            label={["起始上传时间", "结束上传时间"]}*/}
                        {/*            value={[searchData.start_upload_time, searchData.end_upload_time]}*/}
                        {/*            onChangeHandler={handleDateRangeFieldChange('start_upload_time', 'end_upload_time')}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <MultiSelectField
                            id="claim_type"
                            label="Claim Type"
                            selectedValues={searchData.claim_type}
                            onChangeHandler={handleFieldChange('claim_type')}
                            options={claim_options}
                        />
                        <MultiSelectField
                            id="Status"
                            label="Claim Status"
                            selectedValues={searchData.status}
                            onChangeHandler={handleFieldChange('status')}
                            options={options}
                        />
                        <MultiSelectField
                            id="Returned"
                            label="Returned Status"
                            selectedValues={searchData.returned}
                            onChangeHandler={handleFieldChange('returned')}
                            options={returned}
                        />
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="invoice_number"
                            label="账单号"
                            value={searchData.invoice_number}
                            onChangeHandler={handleFieldChange('invoice_number')}
                        />
                        <RenderSelectField
                            idAname="supplier"
                            label="支付类型"
                            value={searchData.payment_type}
                            onChangeHandler={(e, newValue) => handleFieldChanges(e, newValue, "payment_type", "value")}
                            select_options={payment_type_options}
                            v="value"
                            value_name="label"
                        ></RenderSelectField>
                    </Grid>
                    <Grid container>
                        <RenderButtonField
                            idAname="Search"
                            variant="contained"
                            text="查询"
                            buttonType="submit"
                            tip="条件查询"
                            onClickHandler={null}
                            loading={search}
                        />
                        {/*<RenderButtonField*/}
                        {/*    idAname="exportClaim"*/}
                        {/*    variant="contained"*/}
                        {/*    text="导出索赔信息"*/}
                        {/*    buttonType="submit"*/}
                        {/*    tip="查询条件导出"*/}
                        {/*    onClickHandler={handleButtonFieldChange('submitType', 'export')}*/}
                        {/*    loading={search}*/}
                        {/*></RenderButtonField>*/}
                        {/*<Grid item lg={1.5} md={1.5} xs={1.5}>*/}
                        {/*    <FormControl sx={searchFormControl} variant="standard">*/}
                        {/*        <ExportPage type={'CE'}/>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        <FormControl variant="standard" sx={{mr: 5}}>
                            <Tooltip title="查询条件导出">
                                <Button variant="contained" onClick={() => setSearch('export')}>导出查询信息</Button>
                            </Tooltip>
                        </FormControl>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'CC'}/>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ReceiptPage type={'CSR,EFT'}/>
                            </FormControl>
                        </Grid>

                        <RenderButtonField
                            idAname="Clear"
                            variant="outlined"
                            text="清空"
                            buttonType="button"
                            tip="清空查询信息"
                            onClickHandler={handleClearData}
                        />
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                </Box>
            )}
        </span>
    )
}

