import {DataGridPro} from "@mui/x-data-grid-pro";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Slider from '@mui/material/Slider';
import StyledBox from "../../components/StyledBox";
import server from "../../server";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import ToolBar from "../../components/ToolBar";
import Typography from "@mui/material/Typography";

const FuseManagement = "FuseManagement"
function FuseManagementPage () {
  const [Visibility, setVisibility] = useState({
    id: false,
  })
  const [HufFileVisibility, setHufFileVisibility] = useState({
    id: false,
  })
  const [DailyOrdersVisibility, setDailyOrdersVisibility] = useState({
    id: false,
  })
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [hufFilePageSize, setHufFilePageSize] = useState(100);
  const [hufFileCurrentPage, setHufFileCurrentPage] = useState(0);
  const [dailyOrdersCurrentPage, setDailyOrdersCurrentPage] = useState(0);
  const [dailyOrdersPageSize, setDailyOrdersPageSize] = useState(100);
  // 进度条状态
  const [total, setTotal] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [loading, setLoading] = useState(false);
  const [huf_file_loading, setHufFileLoading] = useState(false);
  const [d_loading, setDLoading] = useState(false);

  const [data, setData] = useState([]);
  const [huf_file_data, setHufFileData] = useState([]);
  // 每日打单情况
  const [daily_orders_data, setDailyOrdersData] = useState([]);
  const [searchData, SetSearchData] = useState({});
  const [huf_file_count, setHufFileCount] = useState(0);
  const [daily_orders_count, setDailyOrdersCount] = useState(0);
  const [usra_file_count, setUsraFileCount] = useState(0);
  const marks = [
    {
      value: completed,
      label: `${completed}`,
    },

    {
      value: total,
      label: `${total}`,
    },
  ];
  const Refresh = () => {
    // setData([]);
    setData([]);
    setLoading(true);
    fetchData();

  }
  const HufFileRefresh = () => {
    // setData([]);
    setHufFileData([]);
    setHufFileLoading(true);
    fetchHufFileData();

  }
  const DailyOrderRefresh = () => {
    // setData([]);
    setDailyOrdersData([]);
    setDLoading(true);
    fetchDailyFuseStatisticsData();

  }
  const fetchData = async (sortData={}) => {
    const queryParams = {
      ...searchData, // 将搜索条件作为查询参数
      ...sortData,
      curr_page: currentPage < 1 ? 1 : currentPage,
      page_size: pageSize,
    };
    let requestUrl = server.ursa_file;
    requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
    setLoading(true)
    try {
      await AxiosSyncDefaultRequest({
        url: requestUrl,
        method: 'GET',
        success: (res) => {
          setData(res.data.rows)
          setLoading(false);
          setUsraFileCount(res.data.total)
        },
        fail: (error) => {
          console.error('请求失败', error);
          setData([])
          setLoading(false)
        }
      });
    } catch (error) {
      console.error('请求异常', error);
    }
  };
  const fetchHufFileData = async (sortData={}) => {
    const queryParams = {
      ...searchData, // 将搜索条件作为查询参数
      ...sortData,
      curr_page: hufFileCurrentPage < 1 ? 1 : hufFileCurrentPage,
      page_size: hufFilePageSize,
    };
    let requestUrl = server.get_huf_file;
    requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
    setHufFileLoading(true)
    try {
      await AxiosSyncDefaultRequest({
        url: requestUrl,
        method: 'GET',
        success: (res) => {
          setHufFileData(res.data.rows)
          setHufFileLoading(false);
          setHufFileCount(res.data.total)
        },
        fail: (error) => {
          console.error('请求失败', error);
          setHufFileData([])
          setHufFileLoading(false)
        }
      });
    } catch (error) {
      console.error('请求异常', error);
    }
  };
  const fetchDailyFuseStatisticsData = async (sortData={}) => {
    const queryParams = {
      ...searchData, // 将搜索条件作为查询参数
      ...sortData,
      curr_page: dailyOrdersCurrentPage < 1 ? 1 : dailyOrdersCurrentPage,
      page_size: dailyOrdersPageSize,
    };
    let requestUrl = server.daily_fuse_statistics;
    requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
    setDLoading(true)
    try {
      await AxiosSyncDefaultRequest({
        url: requestUrl,
        method: 'GET',
        success: (res) => {
          setDailyOrdersData(res.data.rows)
          setDLoading(false);
          setDailyOrdersCount(res.data.total)

        },
        fail: (error) => {
          console.error('请求失败', error);
          setDailyOrdersData([])
          setDLoading(false)
        }
      });
    } catch (error) {
      console.error('请求异常', error);
    }
  };
  const fetchTrackingPoolData = async (sortData={}) => {
    const queryParams = {
      // ...searchData, // 将搜索条件作为查询参数
      // ...sortData,
      // curr_page: dailyOrdersCurrentPage < 1 ? 1 : dailyOrdersCurrentPage,
      // page_size: dailyOrdersPageSize,
    };
    let requestUrl = server.tracking_pool_list;
    // requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
    // setDLoading(true)
    try {
      await AxiosSyncDefaultRequest({
        url: requestUrl,
        method: 'GET',
        success: (res) => {
          console.log(res.data.total)
          let Avaliable
          let Used

          if(res.data.total.Avaliable){
            Avaliable = res.data.total.Avaliable
          }
          else {
            Avaliable = 0
          }
          if(res.data.total.Used){
            console.log("Used",res.data.total.Used)
            Used = res.data.total.Used
          }
          else {
            Used = 0
          }
          let total = Avaliable + Used
          console.log("total",total)
          setTotal(total)
          setCompleted(Used)
          // setDailyOrdersData(res.data.rows)
          // setDLoading(false);
          // setDailyOrdersCount(res.data.total)

        },
        fail: (error) => {
          console.error('请求失败', error);
          setDailyOrdersData([])
          setDLoading(false)
        }
      });
    } catch (error) {
      console.error('请求异常', error);
    }
  };
  useEffect(() => {
      fetchData();
      fetchHufFileData();
      fetchDailyFuseStatisticsData();
      fetchTrackingPoolData();
  }, [currentPage, hufFileCurrentPage]);
  const handleSortModelChange = async (sortModel) => {
    let ordering = '';
    if (sortModel.length > 0) {
      // 处理所有的排序模型，虽然通常只有一个
      ordering = sortModel.map(model => {
        return model.sort === 'asc' ? model.field : `-${model.field}`;
      }).join(',');
    }
    // 构造排序的数据结构
    const sortData = { ordering };
    // 调用 fetchData 函数请求数据
    setLoading(true);
    setData([]);
    await fetchData(sortData);
  };
  const handleHufFileSortModelChange = async (sortModel) => {
    let ordering = '';
    if (sortModel.length > 0) {
      // 处理所有的排序模型，虽然通常只有一个
      ordering = sortModel.map(model => {
        return model.sort === 'asc' ? model.field : `-${model.field}`;
      }).join(',');
    }
    // 构造排序的数据结构
    const sortData = { ordering };
    // 调用 fetchData 函数请求数据
    setLoading(true);
    setData([]);
    await fetchHufFileData(sortData);
  };
  const handleDailyFuseStatisticsSortModelChange = async (sortModel) => {
    let ordering = '';
    if (sortModel.length > 0) {
      // 处理所有的排序模型，虽然通常只有一个
      ordering = sortModel.map(model => {
        return model.sort === 'asc' ? model.field : `-${model.field}`;
      }).join(',');
    }
    // 构造排序的数据结构
    const sortData = { ordering };
    // 调用 fetchData 函数请求数据
    setLoading(true);
    setData([]);
    await fetchDailyFuseStatisticsData(sortData);
  };
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Ursa Name',
      flex: 5,
    },
    {field: 'effectiveDate', headerName: "Effective Date", flex: 5},
      {field: 'expirationDate', headerName: "Expiration Date", flex: 5},
      {field: 'downloadDate', headerName: "Download Date", flex: 5},
      {field: 'effected', headerName: "Effected", flex: 5},
  ]
  const huf_file_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'huf_file',
      headerName: 'Huf File',
      flex: 5,
    },
    {field: 'amount', headerName: "Amount", flex: 5},
    {field: 'upload_time', headerName: "Upload Time", flex: 5},
    {field: 'create_time', headerName: "Create Time", flex: 5},
    {field: 'type', headerName: "Type", flex: 5},
    {field: 'sequence_number', headerName: "SequenceNumber", flex: 20},
    {field: 'CustomerManifestId', headerName: "CustomerManifestId", flex: 20},
  ];
  const daily_orders_columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'fedex_carrier',
      headerName: 'Type',
      flex: 5,
    },
    {field: 'total', headerName: "Fuse", flex: 5},
    {field: 'statistics_date', headerName: "Date", flex: 5},

  ]
  function valuetext(value) {
    return value;
  }
  return (
      <Box sx={{minWidth: '100%'}}>
      <Grid  container spacing={3}>
        <Grid item xs={6}>
          <StyledBox  sx={{ width: "100%", overflow: "auto", height: "500px", position: "relative" }}>
            <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 16,
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
            >
              Usra File
            </Typography>
            <DataGridPro
                columns={columns}
                rows={data}
                rowCount={usra_file_count}
                onPageChange={(page) => setCurrentPage(page)}
                onPageSizeChange={pageSize => setPageSize(pageSize)}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                columnBuffer={3}
                columnThreshold={3}
                columnVisibilityModel={Visibility}
                density="compact"
                pagination={true}
                sortingMode="server"
                autoWidth={false}
                rowReordering={false}
                loading={loading}
                onSortModelChange={handleSortModelChange}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: () => <ToolBar onRefresh={Refresh} />,
                }}

            />
          </StyledBox>
        </Grid>
        <Grid item xs={6}>
          <StyledBox  sx={{ width: "100%", overflow: "auto", height: "500px", position: "relative" }}>
            <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 16,
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                    color: "#55b5fb",
                }}
            >
              Huf File
            </Typography>
            <DataGridPro
                columns={huf_file_columns}
                rows={huf_file_data}
                rowCount={huf_file_count}
                onPageChange={(page) => setHufFileCurrentPage(page)}
                onPageSizeChange={pageSize => setHufFilePageSize(pageSize)}
                pageSize={hufFilePageSize}
                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                columnBuffer={3}
                columnThreshold={3}
                columnVisibilityModel={HufFileVisibility}
                density="compact"
                pagination={true}
                sortingMode="server"
                rowReordering={false}
                loading={huf_file_loading}
                onSortModelChange={handleHufFileSortModelChange}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: () => <ToolBar onRefresh={HufFileRefresh} />,
                }}
            />
          </StyledBox>
        </Grid>
        <Grid item xs={6}>
          <StyledBox  sx={{ width: "100%", overflow: "auto" , height: "500px", position: "relative" }}>
            <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 16,
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                    color: "#b34949",
                }}
            >
              每日打单情况
            </Typography>
            <DataGridPro
                columns={daily_orders_columns}
                rows={daily_orders_data}
                rowCount={daily_orders_count}
                onPageChange={(page) => setDailyOrdersCurrentPage(page)}
                onPageSizeChange={pageSize => setDailyOrdersPageSize(pageSize)}
                pageSize={dailyOrdersPageSize}
                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                columnBuffer={3}
                columnThreshold={3}
                columnVisibilityModel={DailyOrdersVisibility}
                density="compact"
                pagination={true}
                sortingMode="server"
                rowReordering={false}
                loading={d_loading}
                onSortModelChange={handleDailyFuseStatisticsSortModelChange}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: () => <ToolBar onRefresh={DailyOrderRefresh} />,
                }}
            />
          </StyledBox>
        </Grid>
        {/*进度条 */}
        <Grid item xs={6}>
          <StyledBox sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 2, p: 2, position: "relative" }}>
            <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 16,
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  color: "#45c3ff",
                }}
            >
              Tracking Pool
            </Typography>
            {total && completed &&
            <Slider
                aria-label="Always visible"
                value={completed} // 固定滑块的值
                getAriaValueText={valuetext}
                step={10}
                marks={marks}
                valueLabelDisplay="on"
                style={{ top: 100 }}
                max={total} // 设置最大值
            />
            }


          </StyledBox>
        </Grid>

      </Grid>
      </Box>
  )
}
export default FuseManagement
export {FuseManagementPage}