import * as React from 'react';
import Button from '@mui/material/Button';
import {connect} from "react-redux";

import Modal from "@mui/material/Modal";
import store from "../../redux/store";
import {CHANGE_PAGE, SHIPPING_FEE_CLOSE, SHIPPING_FEE_OPEN, SHIPPING_FEE_STOP} from "../../redux/type";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {
    DataGridPro,
    GridColumnOrderChangeParams,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
// import APIToolBar from "../../components/APIToolBar";
import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";
import {CellEditShippingFee, ShippingFeeLoad} from "../../redux/actions/RaterAction";
import UploadShippingFeeTemplate from "./UploadShippingFeeTemplate";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadZone from "./UploadZone";
function formatCell(params) {
    const num = parseFloat(params.value);
    if (!["FEDEX_MWT", "UPS_HWT"].includes(params['row']["service_name"])) {
        return num.toFixed(2);
    }
    return num;
}
const columnVisibilityModel = { // 控制是否展示列
    id: false,
}
const localStorageKey = 'ShippingFeeModalColumns';
const localStorageVisibilityKey = 'ShippingFeeVisibilityModalColumns';
const ToolBar = ({ onRefresh,  }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {/*<GridToolbarFilterButton/>*/}
            <GridToolbarDensitySelector />
            {/*<GridToolbarExport/>*/}
            <Button startIcon={<RefreshIcon />} onClick={onRefresh}>
                刷新
            </Button>
            <UploadShippingFeeTemplate />
            {/* 动态渲染额外的选项或按钮 */}
        </GridToolbarContainer>
    );
};

class ShippingFeePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.shipping_fee_open,
            itemId: props['itemId'],
            columns: null,
            Visibility: this.setVisibility
        }

    }

    getColumnsForService(serviceName) {
        let baseColumns = [
            {id: 'id', field: "id", headerName: "ID", hideable: true},
            {id: 'weight', field: "weight", headerName: "重量段", width: 80},
        ];
        let packageColumns = [
            {id: 'id', field: "id", headerName: "ID", hideable: true},
            {id: 'package_type', field: "package_type", headerName: "包裹类型", width: 250},
        ]
        let zoneColumns = [
            {id: 'zone1', field: "zone1", headerName: "无分区价格", width: 120, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone2', field: "zone2", headerName: "zone2", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone3', field: "zone3", headerName: "zone3", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone4', field: "zone4", headerName: "zone4", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone5', field: "zone5", headerName: "zone5", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone6', field: "zone6", headerName: "zone6", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone7', field: "zone7", headerName: "zone7", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone8', field: "zone8", headerName: "zone8", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
            {id: 'zone9', field: "zone9", headerName: "zone9", flex: 10, editable: true, renderCell: (params) => formatCell(params)},
        ];
        let g_h_u = ['UPS_GROUND_R', 'UPS_GROUND', 'FEDEX_GROUND', 'FEDEX_HOME_DELIVERY', 'STANDARD_OVERNIGHT', 'PRIORITY_OVERNIGHT', "FEDEX_2_DAY_STANDARD"]
        let s_p = ['FEDEX_SMART_POST']
        let mwt = ['FEDEX_MWT', "UPS_HWT"]
        let FEDEX_2_DAY_Flat_Rate = ['FEDEX_2_DAY_Flat_Rate']
        let FEDEX_2_DAY_LRN = ['FEDEX_2_DAY_LRN']
        let FEDEX_Freight = ['FEDEX_FREIGHT_ECONOMY']
        let result: [];
        // 判断哪些列需要被删除
        if (g_h_u.includes(serviceName)) {
            zoneColumns = zoneColumns.filter(col => col.id !== 'zone1');
            result = [...baseColumns, ...zoneColumns];
        }else if (s_p.includes(serviceName)) {
            zoneColumns = zoneColumns.filter(col => col.id !== 'zone1');
            result = [...baseColumns, ...zoneColumns];
        }else if (mwt.includes(serviceName)) {
            // zoneColumns.filter(col => !['zone1', 'zone9'].includes(col.id));
            zoneColumns = zoneColumns.filter(col => col.id !== 'zone1' && col.id !== 'zone9');
            result = [...baseColumns, ...zoneColumns];
        }else if (FEDEX_2_DAY_Flat_Rate.includes(serviceName)) {
            // zoneColumns = zoneColumns.filter(col => col.id !== ['zone2', 'zone3', 'zone4', 'zone5', 'zone6', 'zone7', 'zone8', 'zone9']);
            zoneColumns = zoneColumns.filter(col => col.id === 'zone1');
            result = [...packageColumns, ...zoneColumns];
        }else if (FEDEX_2_DAY_LRN.includes(serviceName)) {
            zoneColumns = zoneColumns.filter(col => col.id !== 'zone1' && col.id !== 'zone9');
            result = [...packageColumns, ...zoneColumns];
        }else if (FEDEX_Freight.includes(serviceName)) {
            zoneColumns = zoneColumns.filter(col => col.id !== 'zone1' && col.id !== 'zone9');
            result = [...baseColumns, ...zoneColumns];
        }
        return result;
    }
    columns: GridColDef[] = this.getColumnsForService(this.props.search.service_name)
    // set_columns = SetColumns(localStorageKey, this.columns);
    setVisibility = SetVisibility(localStorageVisibilityKey, columnVisibilityModel);
    // ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
    //     // 获取columns
    //     // console.log(set_columns)
    //     let old = this.set_columns[e.oldIndex]
    //     let target = this.set_columns[e.targetIndex]
    //     this.set_columns[e.targetIndex] = old
    //     this.set_columns[e.oldIndex] = target
    //     localStorage.setItem(localStorageKey, JSON.stringify(this.set_columns))
    //     // oldIndex > targetIndex
    //     // targetIndex < oldIndex
    // }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.ShippingFeeLoad((page * this.props.pageSize), ((page + 1) * this.props.pageSize),
            page, null, this.props.shipping_fee_search)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.ShippingFeeLoad((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, this.props.shipping_fee_search)
        }, 500);
    }
    Close = () => {
        store.dispatch({type: SHIPPING_FEE_CLOSE})
        this.setState({open: !this.state.open})
    }
    Open = () => {
        this.props.ShippingFeeLoad(this.props.page, this.props.pageSize, null, null, this.props.shipping_fee_search)
        store.dispatch({type: SHIPPING_FEE_OPEN})
        this.setState({open: !this.state.open})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().modal.stop && nextProps.shipping_fee_search && !this.props.isLoading) {  // shipping_fee_search
            store.dispatch({type: SHIPPING_FEE_STOP})
            this.props.ShippingFeeLoad(this.props.page, this.props.pageSize, null,
                null, nextProps.shipping_fee_search);
        }
    }

    componentDidMount() { // 预加载
        this.props.ShippingFeeLoad(this.props.page, this.props.pageSize, null,
            null, store.getState().RaterReducer.shipping_fee_search, null, null);
    }
    onRefresh(){
        this.props.ShippingFeeLoad(this.props.page, this.props.pageSize, null,
            null, store.getState().RaterReducer.shipping_fee_search, null, null);

    }


    render() {
        console.log(this.props.shipping_fee_open)
        console.log(this.set_columns)
        return (
            <span>
                <Button startIcon={<ListAltIcon/>} onClick={() => {
                    this.Open()
                }}>运费
                </Button>
                <Modal
                    open={this.state.open}
                    onClose={() => {
                        this.Close()
                    }}
                    aria-labelledby="shipping-fee-title"
                    aria-describedby="shipping-fee-description"
                >
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                {/*<Grid container item md={12} lg={12}>*/}
                {/*    <Box sx={{minWidth: '100%'}}>*/}
                {/*        */}
                {/*    </Box>*/}
                {/*</Grid>*/}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                          <Paper
                              sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: 800,
                              }}
                          >
                          <DataGridPro
                              rows={this.props.data}
                              rowHeight={30}
                              sx={{
                                  minHeight: 750, overflow: 'auto', display: 'block',
                                  '& .MuiDataGrid-cell': {
                                      border: '1px solid rgba(0, 0, 0, 0.1)'
                                  }
                              }}
                              columns={this.columns}
                              // pageSize={this.props.pageSize}
                              // rowsPerPageOptions={[20, 30, 50, 100, 150, 200, 500]}
                              hideFooter={true}
                              autoHeight={true}
                              loading={this.props.TableLoading}
                              rowCount={this.props.rowCount}
                              paginationMode='server'
                              columnVisibilityModel={this.state.Visibility}
                              onColumnVisibilityModelChange={(newModel) =>
                                  this.ChangeColumnVisibilityModel(newModel)
                              }
                              rowReordering={false}
                              // onColumnOrderChange={(e) => {
                              //     this.ColumnOrderChange(e)
                              // }}
                              components={{
                                  LoadingOverlay: LinearProgress,
                                  NoRowsOverlay: CustomNoRowsOverlay,
                                  // Toolbar: APIToolBar,
                                  Toolbar: () => <ToolBar onRefresh={() => this.onRefresh()} />
                              }}
                              // onPageChange={(page) => this.useQuery(page)}
                              // onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                              onCellEditCommit={params => {
                                  this.props.CellEditShippingFee(params)
                              }}
                              editMode={'cell'}
                              pagination={true}
                          />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>

              </Modal>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isLoading: state.RaterReducer.ShippingFeeisLoading,
    TableLoading: state.RaterReducer.ShippingFeeTableLoading,
    pageSize: state.RaterReducer.shipping_fee_pageSize,
    page: state.RaterReducer.shipping_fee_page,
    rowCount: state.RaterReducer.shipping_fee_rowCount,
    data: state.RaterReducer.shipping_fee_list,
    search: state.RaterReducer.shipping_fee_search,
    shipping_fee_open: state.RaterReducer.shipping_fee_open,
    user: state.auth.user
});

export default connect(mapStateToProps, {ShippingFeeLoad, CellEditShippingFee})(ShippingFeePage);