import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    InputAdornment,
    Snackbar, Stack
} from '@mui/material';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import server from "../../../server"
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function DownloadTemplateButton() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadTemplate = (type) => {
        const a = document.createElement('a');
        // 这里添加向后端请求下载文件的逻辑
        if (server.test) {
            a.href = `${server.test_host}:${server.post}/DownloadClaimTemplate?file=${type}.xlsx`;
        } else {
            a.href = `${server.host}/DownloadClaimTemplate?file=${type}.xlsx`;
        }
        a.target = "_blank";
        document.body.appendChild(a); // 添加到body
        a.click();
        a.remove();
        handleClose();
    };

    return (
        <div>
            <Button
                variant="contained"
                onClick={() => downloadTemplate('EFT_Template')}
            >
                下载模板
            </Button>

        </div>
    );
}


function EFTCustomerClaim({ uploadClaim, Refresh }) {
    const [open, setOpen] = useState(false);
    const [uploadClaimFile, setUploadClaimFile] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const [loading, setLoading] = useState(false);
    const [uploadResult, setUploadResult] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const handleClear = () => {
        setInputKey(Date.now());
        setUploadClaimFile(null);
        setUploadResult(null);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (uploadClaimFile){
            // 检查文件大小
            if (uploadClaimFile.size > 50 * 1024 * 1024) {
                setSnackbar({open: true, message: '文件大小超过50M!', severity: 'error'});
                setLoading(false);
                return;
            }
        }
        try {
            if (uploadClaimFile) {
                // 创建FormData对象，并将文件添加进去
                const formData = new FormData();
                formData.append('upload_claim_file', uploadClaimFile, uploadClaimFile.name);

                const result = await uploadClaim(formData, handleClose);
                // handleClose()
            } else {
                setSnackbar({open: true, message: '请添加文件！', severity: 'warning'});
            }
        } catch (e) {
            console.error("上传失败", e);
        } finally {
            setLoading(false);
            // Refresh()

        }
    };
    const handleChangeFile = (e) => {
        // 获取文件并设置，这里我们直接保存文件对象
        const file = e.target.files[0];
        if (file) {
            setUploadClaimFile(file);
        }
    };

    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<UploadOutlinedIcon />} onClick={handleOpen}>
                批量更新EFT
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>批量更新EFT</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <FormControl sx={{ m: 1, minWidth: 500, minHeight: uploadResult ? 50 : 500 }}>
                            <TextField
                                key={inputKey}
                                id="upload_claim_file"
                                name="upload_claim_file"
                                type="file"
                                label="Please upload the claim information file (xlsx format)"
                                onChange={handleChangeFile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FileUploadIcon />
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        accept: ".xlsx",
                                    },
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </FormControl>
                        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={handleClear}>
                                清空
                            </Button>
                            <DownloadTemplateButton
                            />
                            <Button variant="contained" onClick={handleClose}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} type="submit" variant="contained">
                                上传
                            </LoadingButton>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default EFTCustomerClaim;