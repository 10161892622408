import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {RenderButtonField, RenderCloseField, RenderTextField, RenderSelectField} from "../../components/renderField";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";

export default function SearchNewAccountModal({handleSearch, searchData, SetSearchData}) {
    // const [searchObj, setSearchObj] = useState({name: '', address1: '', address2: '', city: '', postal_code: '', state: ''
    // })
    const [searchOpen, setSearchOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [account_type_options, setAccountTypeOptions] = useState([])
    const [return_type_options, setReturnTypeOptions] = useState([])
    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }
    const handleFieldChange = (e, value, key, value_name) => {
        SetSearchData((prev) => ({
            ...prev,
            [key]: value?value[value_name]:null,
        }));
    }
    const fetchAccountTypeData = async () => {
        try {
            setLoading(true)
            let maxNumber = 0
            await AxiosSyncDefaultRequest({
                url: server.account_type,
                method: 'GET',

                success: (res) => {
                    setAccountTypeOptions(res.data.results)
                    setLoading(false)
                },
                // fail: (error) => {
                //     console.error('请求失败', error);
                //     setData([])
                //     setLoading(false)
                // }
            });
        } catch (error) {
            setLoading(false)
            console.error('请求异常', error);
        }
    };
    const fetchReturnTypeData = async () => {
        try {
            setLoading2(true)
            let maxNumber = 0
            await AxiosSyncDefaultRequest({
                url: server.return_type,
                method: 'GET',

                success: (res) => {
                    setReturnTypeOptions(res.data.results)
                    setLoading2(false)
                },
                // fail: (error) => {
                //     console.error('请求失败', error);
                //     setData([])
                //     setLoading(false)
                // }
            });
        } catch (error) {
            setLoading2(false)
            console.error('请求异常', error);
        }
    };

    useEffect(() => {
        setLoading(true)
        setLoading2(true)
        // fetchWareData();
        fetchReturnTypeData()
        fetchAccountTypeData()

    }, []);

    // function handleSubmit() {
    //     if (searchObj.user || searchObj.supplier || searchObj.account_user || searchObj.region) {
    //         console.log("aaa")
    //     } else {
    //         console.log("bbb")
    //     }
    // }
    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            accountNumber: '', accountName: '', returnType: '', accountType: '', suffix: '', status: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchObj).map(([key, value]) => (*/}

                    {RenderTextField({
                        idAname: "accountNumber", label: "Account number",
                        value: searchData.accountNumber,
                        onChangeHandler: (e) => {
                            handleChange(e, "accountNumber", e.target.value)
                        }
                    })}

                    {RenderTextField({
                        idAname: "accountName", label: "Account Name",
                        value: searchData.accountName,
                        onChangeHandler: (e, value) => {
                            handleChange(e, "accountName", e.target.value)
                        }
                    })}
                    {return_type_options && RenderSelectField({
                        idAname: "returnType", label: "Return Type",
                        value: searchData.returnType,
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "returnType", "id")
                        },
                        select_options: return_type_options,
                        v: "id",
                        value_name: "returnTypeName",
                        sx: {bottom: 8}
                    })}


                    {account_type_options && RenderSelectField({
                        idAname: "accountType", label: "Account Type",
                        value: searchData.accountType,
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "accountType", "id")
                        },
                        select_options: account_type_options,
                        v: "id",
                        value_name: "accountTypeName",
                        sx: {bottom: 8}
                    })}
                    {RenderTextField({
                        idAname: "suffix", label: "后缀",
                        value: searchData.postal_code,
                        onChangeHandler: (e) => {
                            handleChange(e, "postal_code", e.target.value)
                        }
                    })}
                    {/*{RenderTextField({*/}
                    {/*    idAname: "state", label: "状态",*/}
                    {/*    value: searchData.state,*/}
                    {/*    onChangeHandler: (e) => {*/}
                    {/*        handleChange(e, "state", e.target.value)*/}
                    {/*    }*/}
                    {/*})}*/}
                    {RenderSelectField({
                        idAname: "status", label: "状态",
                        value: searchData.status,
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "status", "id")
                        },
                        select_options: [{"id": 0, value: "待创建"},{"id": 1, value: "创建失败"}, {"id": 2, value: "创建成功"}],
                        v: "id",
                        value_name: "value",
                        sx: {bottom: 8}
                    })}

                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Reset",
                            variant: "outlined",
                            text: "清空",
                            // buttonType: "submit",
                            tip: "Reset",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                    {/*</>*/}

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
