import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import StyledBox from "../../components/StyledBox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import server from "../../server";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import ToolBar from "../../components/ToolBar";
import CreateNewAccount from "./AccountSingIn/Dialog/CreateNewAccount"
import AuditNewAccount from "./AccountSingIn/Dialog/AuditNewAccount"
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import ImageIcon from '@mui/icons-material/Image';
import SearchNewAccountModal from "../modal/SearchNewAccountModal"

const AccountSignIn = "AccountSignIn";

function AccountSignInPage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(2);
    const [sort_data, setSortData] = useState({});
    const [audit_open, setAuditOpen] = useState(null);
    const [audit_data, setAuditData] = useState(null);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [searchData, SetSearchData] = useState({accountNumber: '', accountName: '', returnType: '', accountType: '', suffix: '', status: ''});
    const [currentImageUrl, setCurrentImageUrl] = useState("");
    const [Visibility, setVisibility] = useState({
        id: false,
    });
    const img_style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const ImageModal = () => {
        return (
            <Modal
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                aria-labelledby="image-modal"
                aria-describedby="image-modal-description"
            >
                <Box sx={img_style}>
                    <img
                        src={currentImageUrl}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Box>
            </Modal>
        );
    };

    const fetchData = async (sortData={}) => {
        const queryParams = {
            ...searchData, // 将搜索条件作为查询参数
            ...sortData,
            curr_page: currentPage < 1 ? 1 : currentPage,
            page_size: pageSize,
        };
        let requestUrl = server.new_account;
        requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
        try {
            await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: 'GET',
                success: (res) => {
                    setData(res.data.rows)
                    setTotalRows(res.data.total)
                    setLoading(false);
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([])
                    setLoading(false)
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true)
        fetchData()
    }, []);

    const handleAuditOpen =(data) => {
        setAuditOpen(true)
        setAuditData(data)
    }

    const columns = [
        { id: 1, field: "id", headerName: "ID", flex: 5 },
        { id: 2, field: "accountNumber", headerName: "Account Number", flex: 5 },
        { id: 3, field: "returnType", headerName: "Return Type", flex: 5  },
        { id: 4, field: "accountType", headerName: "Account Type", flex: 5  },
        { id: 5, field: "suffix", headerName: "账号字母备注", flex: 5  },
        { id: 6, field: "hubId", headerName: "HUBID", flex: 5  },
        { id: 7, field: "remark", headerName: "备注", flex: 5  },
        { id: 8, field: "warehouseAddress", headerName: "地址", flex: 5  },
        { id: 9, field: "create_time", headerName: "创建时间", flex: 5  },
        { id: 10, field: "last_modify_time", headerName: "更新时间", flex: 5  },
        { id: 11, field: "errMsg", headerName: "报错信息", flex: 5  },
        { id: 12, field: "status", headerName: "状态", flex: 5  },
        { id: 13, field: "accountName", headerName: "Account Name", flex: 5  },
        {id: 14, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<MarkChatReadIcon />}
                        label="审核"
                        title={'Audit'}
                        onClick={() => handleAuditOpen(params.row)}
                        key={`audit-${params.id}`}
                        color="primary"
                        disabled={params.row.status === "创建成功" || params.row.status === "创建失败"}
                    />,
                    <GridActionsCellItem
                        icon={<ImageIcon />}
                        label="预览图片"
                        title={'Preview Image'}
                        onClick={() => {
                            setCurrentImageUrl(params.row.imgUrl); // 假设图片 URL 存储在 imageUrl 字段中
                            setImageModalOpen(true);
                        }}
                        key={`preview-image-${params.id}`}
                        color="primary"
                        disabled={!params.row.imgUrl}
                    />,
                ];
            }},
    ];

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        const sortData = { ordering };
        setSortData(sortData);
        setLoading(true);
        setData([]); // 清空数据
        // await fetchData('GET', {}, null, null, null, sortData); // 调用 API 获取数据
        setLoading(false);
    };

    const onChangeColumnVisibilityModel = (newVisibility) => {
        setVisibility(newVisibility);
    };
    const Refresh = () => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData();

    }
    const handleSearch=(e) => {
        setLoading(true)
        fetchData(sort_data)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <CreateNewAccount Refresh={Refresh}/>
            <AuditNewAccount data={audit_data} open={audit_open} setOpen={setAuditOpen} Refresh={Refresh}/>
            <SearchNewAccountModal SetSearchData={SetSearchData} searchData={searchData} handleSearch={handleSearch}/>
            <ImageModal
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                imageUrl={currentImageUrl}
            />
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onSortModelChange={handleSortModelChange}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            columnBuffer={3}
                            columnThreshold={3}
                        />
                    </StyledBox>
                </Grid>
            </Grid>

        </Box>
    );
}

export default AccountSignIn;
export { AccountSignInPage };