import * as React from 'react';
import {connect} from "react-redux";
import store from "../redux/store";
import {
    USER_CLOSE_ALERT,
    USER_ORDERS,
    USER_STOP,
    TABLE_LOADING
} from "../redux/type";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import './styles.scss'
import StyledBox from "../components/StyledBox";
import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import GetPod from "./modal/GetPod"
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import APIToolBar from "../components/APIToolBar";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {LoadUserTable} from "../redux/actions/userAction";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import {DeleteRecord, downloadPOD, downloadFile} from "../redux/actions/modal";
import BatchQueryTraceNumbers from "./modal/BatchQueryTraceNumbers";
import ExportShipment from "./modal/ExportShipment";
import ToolBar from "../components/ToolBar";
import UserErrorPage from "./UserError/ErrorPageModal";
import ErrorDialog from "./UserError/ErrorDialog"

/*
field
headerName
hide
minWidth
width
description
flex 弹性分配
valueGetter
valueFormatter
getActions
headerClassName
cellClassName
*/

const status_chinese = {
    "initializing": "初始化", "Processing": "处理中", "Complete": "完成", "Error": "错误", "Reset": "重置", "Resetting": "重置中"
}

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            Visibility: this.setVisibility,
            record_id: null,
            error_open: false,
            error_data: [],
            error_dialog_open: false,
            error_info: null,
        }
    }

    columnVisibilityModel = { // 控制是否展示列
        id: false,
        download_file_name: false
    }

    localStorageKey = this.props.user.username + "_" + 'UserColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'UserVisibility';
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel)

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    parseSingleQuotedJSON = (str) => {
        if (typeof str !== 'string') {
            throw new Error('Input must be a string');
        }

        // 将单引号 JSON 转换为双引号 JSON
        const doubleQuotedStr = str
            .replace(/(\s*?{\s*?|\s*?,\s*?)(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '$1"$3":')
            .replace(/'/g, '"');

        try {
            // 解析转换后的 JSON 字符串
            return JSON.parse(doubleQuotedStr);
        } catch (e) {
            return null;
        }
    }
    handleError = (error) => {
        if(error) {
            if (error !== "{}" ) {
                if((error.includes("{") && error.includes("}"))){
                let error_data = []
                error = this.parseSingleQuotedJSON(error)
                let id = 1
                if (error) {
                    for (let key in error) {
                        error_data.push({tracking_number: key, message: error[key], id: id})
                        id++
                    }

                    this.setState({...this.state, error_data: error_data, error_open: true})
                }

                }
                else {
                    this.setState({...this.state, error_info: error, error_dialog_open: true})
                }
            }
        }

    }
    columns: GridColDef[] = [
        {id: 0, field: "id", headerName: "Id", flex: 10},
        {id: 1, field: "import_name", headerName: "文件名/导入名", flex: 25},
        {
            id: 2, field: "record_status", headerName: "状态", flex: 8, resizable: false, renderCell: (params) => {
                let chinese_status = status_chinese[params.row.record_status]
                return (
                    <div className={`AdminCellWithStatus ${params.row.record_status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {
            id: 3, field: "record_type", type: 'singleSelect', headerName: "类型", flex: 8,
            valueOptions: ['SE', 'BE', 'POD']
        },
        {id: 7, field: "time_consuming", headerName: "耗时", flex: 10},
        {id: 5, field: "download_file_name", headerName: "download_file_name", flex: 50, hideable: false},
        {
            id: 4, field: "error_message", headerName: "错误信息/其他信息", flex: 20, renderCell: (params) => {
                return (
                    <div
                        style={{
                            cursor: 'pointer',
                            // textDecoration: 'underline'
                        }}
                        onClick={(event) => {
                            event.stopPropagation(); // 防止行选择
                            this.handleError(params.row.error_message);
                        }}
                    >
                        {params.row.error_message}
                    </div>
                )
            }
        },
        {id: 8, field: "create_time", headerName: "创建时间", flex: 10},
        {
            id: 6, field: 'actions', type: 'actions', headerName: "操作", flex: 5, renderCell: (params) => {
                return [
                    <span key={params.row.id}>
                        {/*<Tooltip title="删除该条记录" enterDelay={500} leaveDelay={200}>*/}
                        {/*    <span>*/}
                        {/*    <GridActionsCellItem*/}
                        {/*        icon={<DeleteIcon/>}*/}
                        {/*        label="Delete"*/}
                        {/*        color="primary"*/}
                        {/*        sx={{mr: 1}}*/}
                        {/*        onClick={() => {*/}
                        {/*            this.props.DeleteRecord(params.row.id, this.props.data)*/}
                        {/*        }}*/}
                        {/*        disabled={params.row.record_status === 'Complete'}*/}
                        {/*    />*/}
                        {/*    </span>*/}
                        {/*</Tooltip>*/}
                        <Tooltip title="下载文件" enterDelay={500} leaveDelay={200}>
                            <span>
                                <GridActionsCellItem
                                    icon={<DownloadIcon/>}
                                    label="Download"
                                    onClick={() => {
                                        console.log(params.row.record_type)
                                        if (params.row.record_type === 'POD') {
                                            this.props.downloadPOD(params.row.import_name)
                                        } else {
                                            this.props.downloadFile(params.row.download_file_name)
                                        }
                                    }}
                                    color="primary"
                                    disabled={params.row.record_status !== 'Complete'}
                                />
                            </span>
                        </Tooltip>
                    </span>
                ];
            }
        }
    ]
    set_columns = SetColumns(this.localStorageKey, this.columns)

    componentDidMount() {
        // 用于组件挂载后的初始化加载
        this.loadData();
    }

    loadData(orders = null) {
        // 封装加载数据的逻辑，以便复用
        this.props.LoadUserTable(
            this.props.page,
            this.props.pageSize,
            null,
            null,
            store.getState().userReducer.search,
            null,
            orders
        );
    }

    componentDidUpdate(prevProps) {

        // 检查搜索条件是否有变化
        if (this.props.search !== prevProps.search && this.props.searchClick && !this.props.isLoading) {
            store.dispatch({type: USER_STOP});
            this.loadData();
        }

        // 检查订单条件是否有变化
        if (this.props.orders !== prevProps.orders && this.props.orderClick) {
            store.dispatch({type: USER_STOP});
            this.loadData(this.props.orders);
        }

        // 检查请求条件是否有变化
        if (this.props.request !== prevProps.request && this.props.request) {
            store.dispatch({type: USER_STOP})
            this.loadData(this.props.orders);
        }
    }


    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.LoadUserTable(
            (page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().userReducer.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.LoadUserTable(
                (this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().userReducer.search, null, null)
        }, 100);
    }
    closeAlert = () => {
        store.dispatch({type: USER_CLOSE_ALERT})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: USER_ORDERS, payload: modal})
    }
    Refresh = () => {
        store.dispatch({type: TABLE_LOADING})
        this.loadData()

    }
    render() {
        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{
                width: '100%'
            }}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            {/*<SearchTable user={this.props.user}/>*/}
                            <GetPod/>
                            <BatchQueryTraceNumbers/>
                            <ExportShipment/>
                        </Box>
                    </Grid>
                    <Grid container item md={12} lg={12} spacing={2}>
                        <Grid item md={5} lg={5}>
                            <Paper>
                                <Skeleton variant="rectangular" width="100%" height={200} />
                                <Skeleton width="60%" />
                                <Skeleton width="60%" />
                                <Skeleton width="80%" />
                                <Skeleton width="80%" />
                            </Paper>
                        </Grid>
                        <Grid item md={7} lg={7}>
                            <StyledBox>
                                {this.props.data ? <DataGridPro
                                    density="compact"
                                    rows={this.props.data}
                                    columns={this.set_columns}
                                    pageSize={this.props.pageSize}
                                    rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                                    loading={this.props.TableLoading}
                                    rowCount={this.props.rowCount}
                                    paginationMode='server'
                                    columnVisibilityModel={this.state.Visibility}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        this.ChangeColumnVisibilityModel(newModel)
                                    }
                                    checkboxSelection
                                    headerHeight={70}
                                    rowReordering={false}
                                    onColumnOrderChange={(e) => {
                                        this.ColumnOrderChange(e)
                                    }}
                                    initialState={
                                        {pinnedColumns: {right: ['actions']}}
                                    }
                                    components={{
                                        LoadingOverlay: LinearProgress,
                                        NoRowsOverlay: CustomNoRowsOverlay,
                                        // Toolbar: APIToolBar
                                        Toolbar: () => <ToolBar onRefresh={this.Refresh} />
                                    }}
                                    onPageChange={(page) => this.useQuery(page)}
                                    onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                    pagination={true}

                                    sortingMode="server"
                                    // sortModel={this.set_sort}
                                    onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                    // Number of extra columns to be rendered before/after the visible slice.
                                    // 在可见切片之前/之后渲染的额外列数。
                                    columnBuffer={3}
                                    // Number of rows from the columnBuffer that can be visible before a new slice is rendered.
                                    // 在呈现新切片之前可以看到的 columnBuffer 中的行数。
                                    columnThreshold={3}
                                /> : ''}
                            </StyledBox>
                        </Grid>
                    </Grid>

                </Grid>
                <UserErrorPage error_data={this.state.error_data} open={this.state.error_open} setOpen={() => this.setState({...this.state, error_open: !this.state.error_open})}></UserErrorPage>
                <ErrorDialog error_info={this.state.error_info} open={this.state.error_dialog_open} setOpen={() => this.setState({...this.state, error_dialog_open: !this.state.error_dialog_open})}></ErrorDialog>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.userReducer.isLoading,
    TableLoading: state.userReducer.TableLoading,
    ModalLoading: state.userReducer.ModalLoading,
    pageSize: state.userReducer.pageSize,
    page: state.userReducer.page,
    rowCount: state.userReducer.rowCount,
    Refresh: state.indexReducer.Refresh,
    request: state.userReducer.request,
    data: state.userReducer.data,
    search: state.userReducer.search,
    searchClick: state.userReducer.searchClick,
    orders: state.userReducer.orders,
    orderClick: state.userReducer.orderClick,
    alert: state.userReducer.alert,
    payload: state.userReducer.content,
    user: state.auth.user,
});

export default connect(mapStateToProps, {LoadUserTable, GetPod, downloadPOD, DeleteRecord, downloadFile})(User)