import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar, InputAdornment} from '@mui/material';

import Grid from "@mui/material/Grid";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {Autocomplete, Popper} from '@mui/material';
import {useDispatch} from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
// import {state}  from "./module";
import {RenderSelectField, RenderTextField} from "../../../../components/renderField";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const AuditNewAccount = ({data, open, setOpen, Refresh}) => {
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState({
        id: null,
        status: "1",
        errMsg: null,

    });
    const [uploadClaimFile, setUploadClaimFile] = useState(null);
    const [statusFormData, setStatusFormData] = useState({
        status: {required: true, message: '', status: false},
        errMsg: {required: false, message: '', status: false},
    })


    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    const dispatch = useDispatch();
    useEffect(() => {
        // setLoading(true)
        // fetchWareData();
        if(data){
            setFormData({
                ...formData,
                id: data.id,
                errMsg: data.errMsg,
            })
            // if()
        }


    }, [data]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));


    };

        const handleClear = () => {
            setInputKey(Date.now());
            setUploadClaimFile(null);
            // setUploadResult(null);
        }

    const handleSave = async () => {


        // 创建一个新的对象来存储错误
        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {

                if (statusFormData[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                } else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }
            // }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }
        // if(!uploadClaimFile){
        //     setSnackbar({ open: true, message: "请选择文件", severity: 'error' });
        //     return
        // }

        try {

            setLoading(true);
            const postData = new FormData();
            postData.append("status", formData.status)
            postData.append("errMsg", formData.errMsg)
            postData.append("id", data.id)
            // 如果文件存在，附加文件
            if (uploadClaimFile) {
                postData.append("file", uploadClaimFile); // 文件会自动以二进制形式附加
            }

            // let new_data = JSON.parse(JSON.stringify(formData));
            return await AxiosSyncDefaultRequest({
                url: server.new_account,
                method: "PUT",
                data: postData,
                headers: {
                    'Content-Type': 'multipart/form-data', // 设置请求头
                },
                success:(res) => {
                    // fetchAccountTypeData()
                    // fetchReturnTypeData()
                    setFormData({
                        id: null,
                        status: "1",
                        errMsg: null,
                    })
                    Refresh()

            },
                fail: (error) => {
                    console.error('请求失败', error);
                    // setData([]);
                    setLoading(false);
                    // const errorMessage = JSON.stringify(error.response.data);
                    // setSnackbar({ open: true, message: error, severity: 'error' });
                    // throw error
                }
            })
            // const result = await onSave(formData, 'POST');
            // if (result.status === true) {
            //     setFormData({
            //         accountNumber: null,
            //         accountName: null,
            //         returnType: null,
            //         accountType: null,
            //         warehouseAddress: null,
            //         hubId: null,
            //         suffix: null
            //     })
            //     fetchAccountTypeData()
            //     fetchReturnTypeData()
                // 操作成功，显示成功消息
                // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
            }
         catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }


    };
    const CustomPopper = styled(Popper)(({ theme }) => ({
        '& .MuiAutocomplete-listbox': {
            maxHeight: 200, // 限制下拉框高度
            overflow: 'auto', // 添加滚动条
        },
    }));
    // const handleFieldChange = (e, value, key, value_name) => {
    //     setFormData((prev) => ({
    //         ...prev,
    //         [key]: value[value_name],
    //     }));
    // }
    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadClaimFile(file); // 保存文件对象
        }
    };
    // const defaultState = state.find(state => state.abbreviation === formData.state) || null;
    // const defaultUser = user_options.find(user_options => user_options.id == formData.user) || null
        return (
        <>

            {/*<Button sx={{textTransform: 'none'}} startIcon={<AccountCircleIcon/>} onClick={() => {*/}
            {/*    onOpen()*/}
            {/*}}>*/}
            {/*    账号审核*/}
            {/*</Button>*/}
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>账号审核</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="status"
                                label="是否通过审核"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.status}
                                onChange={handleChange}
                                required={statusFormData.status.required}
                                helperText={statusFormData.status.message}
                                error={statusFormData.status.status}
                            >

                                <MenuItem key="status_1" value={"1"}>
                                    是
                                </MenuItem>
                                <MenuItem key="status_0" value={"2"}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        {formData.status === "2" &&
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="errMsg"
                                label="报错信息"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.errMsg}
                                onChange={handleChange}
                                required={statusFormData.errMsg.required}
                                error={statusFormData.errMsg.status}
                                helperText={statusFormData.errMsg.message}
                                inputProps={{
                                    // minLength: 1,
                                    maxLength: 500
                                }}
                            >
                            </TextField>
                        </Grid>
                        }

                        <Grid item xs={12} md={8}>
                            <TextField
                                key={inputKey}
                                id="upload_claim_file"
                                name="upload_claim_file"
                                type="file"
                                label="Please upload the claim information file"
                                onChange={handleChangeFile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FileUploadIcon />
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        accept: "image/jpeg, image/png"
                                    },
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>

                    <Button variant="contained" onClick={handleClear}>
                        清空文件
                    </Button>

                    <LoadingButton loading={loading} onClick={handleSave}  variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
}
    ;

    export default AuditNewAccount;
